.site-nav {
    .btn-toggle-menu {
        display: none;
    }

    ul {
        @include flex(
            $align: center
        );

        > li {
            & + li {
                margin: 0 0 0 40px;
            }

            > a {
                color: $brand-color-secondary;
                display: block;
                font-family: $text-font-secondary;
                font-size: 20px;
                font-weight: 400;
                text-transform: uppercase;
                @include transition;

                &:hover {
                    color: $brand-color;
                }

                &.active {
                    color: $brand-color;
                }
            }
        }
    }

    @include breakpoint-min(large) {
        .toggle-menu {
            &.mobile {
                display: block !important;
            }
        }
    }

    @include breakpoint-max(large) {
        margin: 30px 0 0;
        max-width: 480px;
        width: 100%;

        .btn-toggle-menu {
            background-color: $brand-color;
            color: #ffffff;
            display: block;
            font-family: $text-font-secondary;
            font-size: 14px;
            font-weight: 400;
            padding: 12px 15px;
            position: relative;
            text-transform: uppercase;
            width: 100%;

            &::after {
                @include imageBg(
                    $image: "menu.svg",
                    $size: contain
                );
                content: "";
                position: absolute;
                right: 15px;
                top: 0;
                @include size(18px, 100%);
            }
        }

        .toggle-menu {
            display: none;
        }

        ul {
            background-color: $brand-color;
            display: block;
            padding: 0 15px 15px;

            > li {
                & + li {
                    margin: 1px 0 0;
                }

                > a {
                    background-color: rgba(#000000, 0.2);
                    color: #ffffff;
                    font-size: 14px;
                    padding: 12px 15px;
                    text-align: center;

                    &:hover,
                    &.active {
                        background-color: rgba(#000000, 0.35);
                        color: #ffffff;
                    }
                }
            }
        }
    }
}