.site-header {
    background-color: #ffffff;
    box-shadow: 0 5px 15px rgba(#000000, 0.5);
    padding: 25px 0;
    position: relative;
    z-index: 3;

    .container {
        @include flex(
            $align: center,
            $justify: space-between
        );
    }

    @include breakpoint-max(large) {
        .container {
            flex-direction: column;
        }

        .logo {
            > a {
                display: block;
            }
        }
    }
}