// Display Flex
%flex {
    display: flex;
    flex-wrap: wrap;
}

// Row
%row {
    @extend %flex;
    margin-right: -15px;
    margin-left: -15px;
}