.page-contact {
    .page-header {
        margin: 0 0 70px;
    }

    .page-content {
        .form-alert {
            &.error {
                display: block;
                margin: 0 0 20px;
                padding: 10px 15px;
                text-align: left;
            }

            &.success {
                margin: 0 0 50px;
            }
        }

        .box-form {
            padding: 0 0 80px;
            position: relative;
            z-index: 1;
        }
    }

    .page-map {
        > iframe {
            display: block;
            @include size(100%, 350px);
        }
    }

    @include breakpoint-min(large) {
        .page-content {
            .box-form {
                &::before {
                    @include imageBg(
                        $image: "pattern.png",
                        $pos: 0 0,
                        $repeat: repeat
                    );
                    content: "";
                    left: 20%;
                    position: absolute;
                    top: 50px;
                    @include size(1000%, calc(100% - 50px));
                    z-index: -1;
                }
            }
        }
    }
}