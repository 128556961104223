.page-office {
    .page-content {
        .box-text {
            padding: 70px 0;
            position: relative;
        }
    }

    .page-infos {
        background-color: #b80f07;

        .item {
            @include linearGradient(
                $color1: rgba(#000000, 0.3),
                $color2: $brand-color 50%,
                $angle: 120deg
            );
            color: #ffffff;
            padding: 60px 35px;
            position: relative;
            width: 33.33%;

            > h2 {
                font-size: 30px;
                margin: 0 0 15px;
                text-transform: uppercase;
            }
        }

        @include breakpoint-min(large) {
            .container {
                @include flex();
            }
        }

        @include breakpoint-max(large) {
            .container {
                padding: 0;
            }

            .item {
                padding: 30px 30px;
                width: 100%;

                & + .item {
                    margin: 1px 0 0;
                }
            }
        }
    }

    @include breakpoint-min(large) {
        .page-content {
            .box-text {
                max-width: 640px;
                padding-right: 80px;
                width: 100%;
        
                &::before {
                    @include imageBg(
                        $image: "pattern.png",
                        $pos: 0 0,
                        $repeat: repeat
                    );
                    content: "";
                    left: 100%;
                    position: absolute;
                    top: 0;
                    @include size(1000%, 100%);
                    z-index: -1;
                }
            }
        }
    }
}