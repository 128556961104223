.container {
    margin-left: auto;
    margin-right: auto;
    max-width: $max-width;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
}
.container-fluid {
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
}
.row {
    @extend %row;
    &-reverse {
        @extend %row;
        flex-direction: row-reverse
    }
    &-column {
        @extend %row;
        flex-direction: column;
    }
    &-column-reverse {
        @extend %row;
        flex-direction: column-reverse;
    }
}
.grid {
    &-1,
    &-2,
    &-3,
    &-4,
    &-5,
    &-6,
    &-7,
    &-8,
    &-9,
    &-10,
    &-11,
    &-12 {
        padding-left: 15px;
        padding-right: 15px;
    }
    &-12{width:100%}
    &-11{width:91.66666667%}
    &-10{width:83.33333333%}
    &-9{width:75%}
    &-8{width:66.66666667%}
    &-7{width:58.33333333%}
    &-6{width:50%}
    &-5{width:41.66666667%}
    &-4{width:33.33333333%}
    &-3{width:25%}
    &-2{width:16.66666667%}
    &-1{width:8.33333333%}
}