// Font Size Default
$font-size-default: 20px;

// Font family Primary
$text-font: 'Assistant', sans-serif !default;

// Font family Primary
$text-font-secondary: 'Bree Serif', serif !default;

$font-primary-weight: (
  'extralight': 200,
  'regular': 400,
  'bold': 700
) !default;

// Brand color
$brand-color: #e00a00 !default;

// Brand color secondary
$brand-color-secondary: #172223;

// Text color light
$text-color-light: #999999 !default;

// Copy text color dark
$text-color-dark: #4c4c4c !default;

// Error
$error: #cc0000 !default;

// Success
$success: #27ae60 !default;

// Border Color
$border-color: #e5e5e5 !default;

// Container's maximum width
$max-width: 1200px !default;

// Breakpoints map
// @prop {String} keys - Keys are identifiers mapped to a given length
// @prop {Map} values - Values are actual breakpoints expressed in pixels
$breakpoints-max: (
  'small':  (max-width: 575px),
  'medium': (max-width: 767px),
  'large':  (max-width: 991px),
  'xlarge': (max-width: ($max-width - 1px))
) !default;

// Breakpoints map
// @prop {String} keys - Keys are identifiers mapped to a given length
// @prop {Map} values - Values are actual breakpoints expressed in pixels
$breakpoints-min: (
  'small':  (min-width: 576px),
  'medium': (min-width: 768px),
  'large':  (min-width: 992px),
  'xlarge': (min-width: $max-width)
) !default;