// Flexbox
.flex {
  &-row {
      @extend %flex;
      flex-direction: row;
  }
  &-row-reverse {
      @extend %flex;
      flex-direction: row-reverse;
  }
  &-column {
      @extend %flex;
      flex-direction: column;
  }
  &-column-reverse {
      @extend %flex;
      flex-direction: column-reverse;
  }
}


// Align Items
.a-start {
  align-items: flex-start;
}
.a-center {
  align-items: center;
}
.a-end {
  align-items: flex-end;
}
.a-stretch {
  align-items: stretch;
}


// Justify Items
.j-start {
  justify-content: flex-start;
}
.j-center {
  justify-content: center;
}
.j-end {
  justify-content: flex-end;
}
.j-around {
  justify-content: space-around;
}
.j-between {
  justify-content: space-between;
}


// Hide text while making it readable for screen readers
.hide-text {
  overflow: hidden;
  text-indent: -9999px;
  white-space: nowrap;
}


// Hide element while making it readable for screen readers
.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}