// Loading
.loading {
    background-color: $brand-color;
    box-sizing: border-box;
    color: #ffffff;
    cursor: default;
    @include flex(
        $align: center,
        $justify: center
    );
    font-size: 14px;
    font-weight: 400;
    line-height: 1.15em;
    padding: 12px 15px;
    text-transform: uppercase;

    > span {
        display: table;
        margin: 0 auto;
        padding: 0 0 0 25px;
        position: relative;
        
        .loader {
            animation: loader 1.1s infinite linear;
            border-bottom: 3px solid rgba(#ffffff, .3);
            border-left: 3px solid #ffffff;
            border-top: 3px solid rgba(#ffffff, .3);
            border-right: 3px solid rgba(#ffffff, .3);
            border-radius: 50%;
            box-sizing: border-box;
            height: 16px;
            left: 0;
            margin: -8px 0 0 0;
            position: absolute;
            top: 50%;
            width: 16px;
        }
    }
}