.page-team {
    .container {
        >.inner {
            padding: 70px 0 90px;

            >.row {
                margin-bottom: -40px;
            }
        }
    }

    .section-title {
        margin: 0 0 40px;
    }

    .item {
        margin: 0 0 40px;

        >.inner {
            margin: 0 auto;
            max-width: 445px;
            width: 100%;
        }

        .item-image {
            margin: 0 0 20px;
        }

        .item-title {
            color: $brand-color;
            font-family: $text-font;
            font-size: 30px;
            font-weight: weight(bold);
        }

        .box-text {
            border-top: solid 1px #dad5d0;
            margin: 10px 0 0;
            padding: 10px 0 0;

            p {
                font-size: 18px;
            }
        }
    }

    @include breakpoint-min(large) {
        .container {
            >.inner {
                position: relative;
                z-index: 1;
                
                &::before {
                    @include imageBg(
                        $image: "pattern.png",
                        $pos: 0 0,
                        $repeat: repeat
                    );
                    content: "";
                    left: 30%;
                    position: absolute;
                    top: 0;
                    @include size(1000%, 340px);
                    z-index: -1;
                }
            }
        }
    }

    @include breakpoint-max(large) {
        .container {
            >.inner {
                padding: 50px 0;
            }
        }

        .item {
            width: 100%;

            .item-title {
                font-size: 24px;
            }
        }
    }
}