// Home Slide
.home-slide {
    background-color: #e5e5e5;

    .swiper-slide {
        @include imageBg(
            $size: cover
        );
        height: 550px;

        >.container {
            @include flex(
                $align: center,
                $justify: flex-end
            );
            height: 100%;
        }

        .slide-content {
            @include flex(
                $direction: column,
                $align: flex-start
            );
            max-width: 480px;
            width: 100%;

            >.title-small {
                background-color: $brand-color-secondary;
                color: #ffffff;
                font-size: 20px;
                font-weight: weight(regular);
                line-height: 1.2em;
                max-width: 380px;
                padding: 3px 15px 5px;
                width: 100%;
            }

            >.title-big {
                background-color: darken(rgba($brand-color, 0.85), 12%);
                color: #ffffff;
                font-size: 47px;
                padding: 3px 20px 12px;
                position: relative;
                text-transform: uppercase;
                width: 100%;
                z-index: 1;
            }

            >.description {
                background-color: #ffffff;
                color: $brand-color-secondary;
                font-size: 20px;
                line-height: em(24, 20);
                padding: 12px 20px;
                width: 100%;
            }

            >.link {
                @include flex(
                    $justify: flex-end
                );
                width: 100%;

                > a {
                    background: $brand-color;
                    color: #ffffff;
                    @include flex(
                        $align: center,
                        $justify: center
                    );
                    font-family: $text-font-secondary;
                    font-weight: 400;
                    font-size: 20px;
                    padding: 5px 12px 10px;
                    @include transition;

                    &::after {
                        @include imageBg(
                            $image: "arrow-right.png"
                        );
                        display: block;
                        content: "";
                        margin: 5px 0 0 10px;
                        @include size(10px, 20px);
                    }

                    &:hover {
                        background-color: $brand-color-secondary;
                    }
                }
            }
        }
    }

    .slide-pagination {
        bottom: 20px;
        @include flex(
            $align: center,
            $justify: center
        );
        left: 0;
        padding: 5px 0;
        pointer-events: none;
        position: absolute;
        width: 100%;
        z-index: 1;

        .bullet {
            background-color: #ffffff;
            border-radius: 50%;
            display: block;
            pointer-events: all;
            @include transition;
            @include size(10px);

            & + .bullet {
                margin: 0 0 0 10px;
            }

            &-active {
                box-shadow: 0 0 0 2px #ffffff;
            }
        }
    }

    @include breakpoint-max(large) {
        .swiper-slide {
            height: 450px;

            .container {
                justify-content: center;
            }

            .slide-content {
                text-align: center;

                >.title-small {
                    font-size: 16px;
                    max-width: 100%;
                }

                >.title-big {
                    font-size: 30px;
                    padding: 5px 15px 10px;
                }

                >.description {
                    font-size: 15px;
                    font-weight: 400;
                }

                >.link {
                    > a {
                        font-size: 16px;
                    }
                }
            }
        }
    }

    @include breakpoint-max(small) {
        .swiper-slide {
            height: 350px;

            .slide-content {
                >.title-big {
                    font-size: 24px;
                }
            }
        }
    }
}

// Home About
.home-about {
    .box-content {
        .box-link {
            @include flex(
                $justify: flex-start
            );
            margin: 50px 0 0;

            > a {
                background: $brand-color;
                color: #ffffff;
                @include flex(
                    $align: center,
                    $justify: center
                );
                font-family: $text-font-secondary;
                font-weight: 400;
                font-size: 20px;
                padding: 5px 12px 10px;
                @include transition;

                &::after {
                    @include imageBg(
                        $image: "arrow-right.png"
                    );
                    display: block;
                    content: "";
                    margin: 5px 0 0 10px;
                    @include size(10px, 20px);
                }

                &:hover {
                    background-color: $brand-color-secondary;
                }
            }
        }
    }

    .box-image {
        @include flex(
            $justify: flex-end
        );

        > figure {
            position: relative;
            z-index: 1;
        }
    }

    @include breakpoint-min(large) {
        .box-content {
            .inner {
                padding: 180px 0 50px;
            }
        }

        .box-image {
            > figure {
                padding: 160px 0;
                position: relative;
                z-index: 1;
        
                &::before {
                    @include imageBg(
                        $image: "pattern.png",
                        $pos: 0 0,
                        $repeat: repeat
                    );
                    content: "";
                    left: 30%;
                    position: absolute;
                    top: 0;
                    @include size(1000%, 100%);
                    z-index: -1;
                }
            }
        }
    }

    @include breakpoint-max(large) {
        padding: 60px 0;

        .box-content {
            margin: 30px auto 0;
            max-width: 580px;
            width: 100%;

            .box-link {
                margin: 30px 0 0;
            }
        }


        .box-image {
            justify-content: center;
            order: -1;
            width: 100%;
        }
    }
}