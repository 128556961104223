.box-text {
    color: $brand-color-secondary;
    
    p {
        font-size: 20px;
        line-height: em(28, 20);

        & + p {
            margin: 15px 0 0;
        }
    }

    &.-light {
        color: #ffffff;
    }

    @include breakpoint-max(large) {
        p {
            font-size: 18px;
            line-height: em(26, 18);
        }
    }
}