// Convert px to em
@function em($pixels, $context: $font-size-default) {

  @if (unitless($pixels)) {
    $pixels: $pixels * 1px;
  }

  @if (unitless($context)) {
    $context: $context * 1px;
  }

  @return $pixels / $context * 1em;
}

// Get Font Weight
@function weight($weight: regular, $font: primary) {
  @if($font == primary) {
    @return map-get($font-primary-weight, $weight);
  } @else {
    @return map-get($font-secondary-weight, $weight);
  }
}

// Convert Pixel to Percentage
@function percent($obj, $context) {
  @return (($obj * 100) / $context) * 1%;
}