.section-title {
    color: $brand-color;
    font-size: 47px;
    margin: 0 0 15px;
    text-transform: uppercase;

    @include breakpoint-max(large) {
        font-size: 35px;
    }

    @include breakpoint-max(small) {
        font-size: 25px;
    }
}