// Generator Keyframes
@mixin keyframes($name) {
	@keyframes #{$name} {
		@content;
	}
}

// FlexBox
@mixin flex(
    $align: false,
    $justify: false,
    $direction: row,
    $wrap: wrap
) {
	@if($align) {
		align-items: $align;
	}
	display: flex;
	@if($direction) {
		flex-direction: $direction;
	}
	flex-wrap: $wrap;
	
	@if($justify) {
		justify-content: $justify;
	}
}

// Helper to size an element
// @author Hugo Giraudel
// @param {Length} $width
// @param {Length} $height
@mixin size($width, $height: $width) {
	height: $height;
	width: $width;
}

// Helper to import images
@mixin imageBg(
	$image: false,
	$pos: center center,
	$size: false,
	$repeat: no-repeat
) {
	@if($image) {
		background-image: url(../images/#{$image});
	}
	background-position: $pos;
	background-repeat: $repeat;
	@if($size) {
		background-size: $size;
	}
}

// Linear Gradient
@mixin linearGradient(
	$angle: 90deg,
	$color1: $brand-color,
	$color2: darken($brand-color, 10%)
) {
	background-image: linear-gradient($angle, $color1, $color2);
}

// Helper to import sprite
@mixin sprite(
	$pos: center 0
) {
	background-image: url(../images/sprite-icons.png);
	background-position: $pos;
	background-repeat: no-repeat;
}

// Transition
@mixin transition(
	$prop: all,
	$duration: 0.3s,
	$function: ease
) {
	transition: $prop $duration $function;
}

// Mixin to manage responsive breakpoints
// @require $breakpoints-max
@mixin breakpoint-max($breakpoint: false) {
	@if($breakpoint != false) {
		@if map-has-key($breakpoints-max, $breakpoint) {
			@media #{inspect(map-get($breakpoints-max, $breakpoint))} {
				@content;
			}
		} @else if($breakpoint) {
			@media (max-width: $breakpoint) {
				@content;
			}
		} @else {
			@warn "Nenhum valor encontrado para `#{$breakpoint}`. " + "Breakpoints disponiveis: #{map-keys($breakpoints-max)}.";
		}
		
	} @else {
		@warn "Informe um valor para `$breakpoint`. Breakpoints disponiveis: #{map-keys($breakpoints-max)} ou um valor customizado em px (Ex.: 767px)";
	}
}

// Mixin to manage responsive breakpoints
// @require $breakpoints-min
@mixin breakpoint-min($breakpoint: false) {
	@if($breakpoint != false) {
		@if map-has-key($breakpoints-min, $breakpoint) {
			@media #{inspect(map-get($breakpoints-min, $breakpoint))} {
				@content;
			}
		} @else if($breakpoint) {
			@media (min-width: $breakpoint) {
				@content;
			}
		} @else {
			@warn "Nenhum valor encontrado para `#{$breakpoint}`. " + "Breakpoints disponiveis: #{map-keys($breakpoints-min)}.";
		}
		
	} @else {
		@warn "Informe um valor para `$breakpoint`. Breakpoints disponiveis: #{map-keys($breakpoints-min)} ou um valor customizado em px (Ex.: 767px)";
	}
}


// Placehlders
@mixin placeholder {
	&::-webkit-input-placeholder {@content}
	&:-moz-placeholder {@content}
	&::-moz-placeholder {@content}
	&:-ms-input-placeholder {@content}
}