.page-404 {
    padding: 0 0 60px;

    .container {
        @include flex(
            $align: center,
            $justify: center
        );
        min-height: 380px;
    }

    .content {
        @include flex(
            $direction: column,
            $align: center
        );
        max-width: 560px;
        padding: 80px 0 0;
        text-align: center;
        > h1 {
            color: $brand-color;
            font-size: 80px;
            text-transform: uppercase;
        }
        > h2 {
            color: $brand-color;
            font-size: 30px;
            line-height: 1.350em;
            margin: 0;
        }
        > h3 {
            border-bottom: solid 1px #e5e5e5;
            display: table;
            font-size: 20px;
            margin: 30px 0 0;
            padding: 0 0 20px;
        }

        .btn-link {
            margin: 20px 0 0;

            > a {
                border: solid 1px $brand-color;
                color: $brand-color;
                display: block;
                font-size: 20px;
                font-weight: weight(regular);
                padding: 10px 15px;
                @include transition;

                &:hover {
                    background-color: $brand-color;
                    color: #ffffff;
                }
            }
        }

        @include breakpoint-max(medium) {
            > h1 {
                font-size: 80px;
            }
            > h2 {
                font-size: 24px;
                margin: 10px 0 0;
            }
            > h3 {
                font-size: 18px;
            }
        }

        @include breakpoint-max(small) {
            padding: 50px 0 0;
            > h1 {
                font-size: 80px;
            }
            > h2 {
                font-size: 20px;
            }
            > h3 {
                font-size: 16px;
            }
        }
    }
}