.page-services {
    .page-content {
        .box-text {
            padding: 70px 0;
            position: relative;

            .section-title {
                margin: 0 0 30px;
            }

            p {
                padding: 0 20px;

                & + p {
                    border-top: solid 1px #dfdbd8;
                    margin: 10px 0 0;
                    padding-top: 10px;
                }
            }
        }
    }

    @include breakpoint-min(large) {
        .page-content {
            .box-text {
                max-width: 640px;
                padding-right: 80px;
                width: 100%;
        
                &::before {
                    @include imageBg(
                        $image: "pattern.png",
                        $pos: 0 0,
                        $repeat: repeat
                    );
                    content: "";
                    left: 100%;
                    position: absolute;
                    top: 0;
                    @include size(1000%, 100%);
                    z-index: -1;
                }
            }
        }
    }

    @include breakpoint-max(large) {
        .page-content {
            .box-text {
                padding: 50px 0;
            }
        }
    }
}