// Set up a decent box model on the root element
html {
	box-sizing: border-box;
}

/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
*,
*::before,
*::after {
	box-sizing: border-box;
	outline: none !important;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
    color: $text-color-light;
    font-family: $text-font;
    font-size: $font-size-default;
    line-height: 1.2em;
    font-weight: weight(extralight);
    overflow-y: scroll;
}

// Wrapper
.wrapper {
    overflow: hidden;
}

// Headings
h1, h2, h3, h4, h5, h6 {
    font-family: $text-font-secondary;
    font-weight: 400;
    line-height: 1.2em;
    margin: 0
}
h1 {font-size: 25px}
h2 {font-size: 23px}
h3 {font-size: 21px}
h4 {font-size: 19px}
h5 {font-size: 17px}
h6 {font-size: 15px}


// Basic styles for links
a {
	color: $text-color-dark;
	line-height: 1.2em;
    text-decoration: none;
    &:hover {
        color: $brand-color;
    }
}

// Strong
strong, b {
    font-weight: weight(bold);
}


// Img and SVG
img,
svg {
    height: auto;
    max-width: 100%;
    vertical-align: middle;
}
img {
    box-sizing: content-box;
}

// Form inputs
input,
select,
textarea,
button {
    appearance: none;
        -webkit-appearance: none;
    border: none;
    box-sizing: border-box;
    box-shadow: none;
    color: $brand-color-secondary;
    display: block;
    font-family: inherit;
    font-size: 18px;
    font-weight: weight(regular);
    line-height: 1.150em;
    margin: 0;
    outline: none;
    padding: 10px 15px;
    position: relative;
    resize: none;
}
input[type="text"],
input[type="number"],
input[type="password"],
input[type="email"],
input[type="tel"],
input[type="file"],
input[type="search"],
select,
textarea {
    background-color: #ffffff;
    border: solid 1px $brand-color-secondary;
    width: 100%;
}
textarea {
    height: 225px;
}

// Hidden
.hidden {
    display: none !important;
}

// Visible
.visible {
    display: block !important;
}

// Form Alerts
.form-alert {
    box-sizing: border-box;
    @include flex(
        $direction: column,
        $align: center,
        $justify: center
    );
    padding: 30px;
    text-align: center;
    width: 100%;
    svg {
        height: 50px;
        margin: 0 0 10px;
        width: 50px;
        path {
            fill: $brand-color;
        }
    }
    p {
        display: block;
        font-size: 18px;
        font-weight: weight(regular);
        line-height: 1.4em;
    }
    h2 {
        font-size: 24px;
        font-weight: weight(regular);
        line-height: 1.3em;
        margin: 0;
    }
    span {
        display: block;
        font-size: 18px;
        font-weight: weight(regular);
        line-height: 1.4em;
    }
    
    &.success {
        background-color: rgba($success, 0.1);
        border: solid 1px rgba($success, 0.12);
        color: $success;

        svg {
            path {
                fill: $success;
            }
        }

        .btn-link {
            margin: 20px 0 0;

            > a {
                border: solid 1px $success;
                color: $success;
                display: block;
                font-size: 20px;
                font-weight: weight(regular);
                padding: 10px 15px;
                @include transition;

                &:hover {
                    background-color: $success;
                    color: #ffffff;
                }
            }
        }
    }
    &.error {
        background-color: rgba($error, 0.1);
        border: solid 1px rgba($error, 0.12);
        color: $error;
        svg {
            path {
                fill: $error;
            }
        }
    }
}

// Box Form
.box-form {
    .field-box {
        position: relative;

        & + .field-box {
            margin: 20px 0 0;
        }

        > label {
            color: $brand-color-secondary;
            display: table;
            font-size: 18px;
            font-weight: weight(regular);
            margin: 0 0 5px;
            text-transform: uppercase;
        }
    }

    .box-buttons {
        @include flex(
            $justify: flex-end
        );
        margin: 20px 0 0;
        width: 100%;

        >.button {
            background: $brand-color;
            color: #ffffff;
            cursor: pointer;
            @include flex(
                $align: center,
                $justify: center
            );
            font-family: $text-font-secondary;
            font-weight: 400;
            font-size: 20px;
            padding: 5px 15px 10px;
            @include transition;

            &::after {
                @include imageBg(
                    $image: "arrow-right.png"
                );
                display: block;
                content: "";
                margin: 5px 0 0 10px;
                @include size(10px, 20px);
            }

            &:hover {
                background-color: $brand-color-secondary;
            }
        }
    }

    @include breakpoint-max(large) {
        .fields-group {
            width: 100%;

            & + .fields-group {
                margin: 20px 0 0;
            }
        }

        .box-buttons {
            justify-content: center;
        }
    }
}

// Page Header
.page-header {
    @include imageBg(
        $size: cover
    );
    height: 330px;
    
    @include breakpoint-max(large) {
        height: 180px;
    }
    
    @include breakpoint-max(small) {
        height: 120px;
    }
}