.site-footer {
    background-color: $brand-color-secondary;
    border-top: solid 2px $brand-color;
    padding: 20px 0;
    position: relative;
    z-index: 2;

    .container {
        @include flex(
            $align: center,
            $justify: space-between
        );
    }

    // Footer Social
    .footer-social {
        > ul {
            @include flex(
                $align: center
            );

            > li {
                & + li {
                    margin: 0 0 0 20px;
                }

                > a {
                    border: solid 2px $brand-color;
                    border-radius: 50%;
                    color: $brand-color;
                    @include flex(
                        $align: center,
                        $justify: center
                    );
                    font-size: 18px;
                    @include transition;
                    @include size(40px);

                    &:hover {
                        background-color: $brand-color;
                        color: #ffffff;
                    }
                }
            }
        }
    }

    // Footer Adrress
    .footer-address {
        max-width: 270px;
        padding: 0 0 0 90px;
        position: relative;
        text-transform: uppercase;
        width: 100%;

        &::before {
            @include imageBg(
                $image: "icon-location.png"
            );
            content: "";
            left: 0;
            position: absolute;
            top: -30px;
            @include size(64px, 106px);
        }

        > a {
            color: $brand-color;
            display: table;
            font-size: 20px;
            font-weight: weight(bold);
            margin: 0 0 5px;
            @include transition;

            &:hover {
                color: #ffffff;
            }
        }

        > p {
            color: #ffffff;
            font-size: 18px;
            line-height: 1.2em;
            text-transform: uppercase;
        }
    }

    // Footer Aqua
    .footer-aqua {
        display: block;
        opacity: 1;
        @include transition;

        &:hover {
            opacity: 0.75;
        }
    }

    @include breakpoint-max(large) {
        .container {
            flex-direction: column
        }

        .footer-address {
            margin: 40px 0 0;

            &::before {
                top: -20px;
            }
        }

        .footer-aqua {
            margin: 30px 0 0;
        }
    }
}